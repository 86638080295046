@tailwind base;
@tailwind components;
@tailwind utilities;

.mydesign:hover .myborder {
    border:1px solid  white;
}
.mydesign:hover .myname {
   color: white;
}

.mydesign:hover .mymore {
    color: white;
 }